<template>
  <header class="sectionHeader">
    <slot>
      <h2 class="h3">Your Tokens</h2>
    </slot>
    <p class="wallet-stats">
      {{ tokenCount }} {{ tokenCount === 1 ? 'token' : 'tokens' }}
      ({{ tokenClassCount }} {{ tokenClassCount === 1 ? 'class' : 'classes' }})
    </p>
  </header>

  <ul v-if="tokenClassCount > 0" class="u-stripped-list grid-container" :class="gridContainerMod">
    <li v-for="(token, id) in tokens" :key="id">
      <TokenListItem v-bind="token" />
    </li>
  </ul>
  <div v-else>
    No tokens
  </div>
</template>


<script>
  import TokenListItem from '@/components/TokenListItem.vue';

  const gridContainerDensities = new Map([
    ['M', 'medium'],
    ['H', 'high'],
    ['HH', 'very-high'],
  ]);

  export default {
    name: 'TokenList',
    props: {
      tokens: { type: Object, required: true },
      tokenCount: { type: Number, required: true },
      tokenClassCount: { type: Number, required: true },

      density: {
        type: String,
        default: 'M',
        validator: (val) => gridContainerDensities.has(val),
      },
    },
    components: {
      TokenListItem,
    },
    computed: {
      gridContainerMod() {
        return `--${gridContainerDensities.get(this.density)}-density`;
      },
    },
  };
</script>


<style lang="scss" scoped>
  header {
    align-items: baseline;

    @include for-tablet-up {
      display: flex;
    }
  }

  .wallet-stats {
    margin: 9px 0 0;

    @include for-tablet-up {
      margin-left: 1em;
    }
  }
</style>
