import metaMaskHelper from '@/common/metaMaskHelper';


const state = {
  isMetaMaskInstalled: false,
  isMetaMaskError: false,
  isUserDisconnectFlag: false,
  isConnectedToBSCNetwork: false,
  walletAddress: '',
};


const mutations = {
  SET_IS_METAMASK_INSTALLED(state) { state.isMetaMaskInstalled = true; },
  SET_IS_METAMASK_ERROR(state) { state.isMetaMaskError = true; },
  SET_USER_DISCONNECT_FLAG(state) { state.isUserDisconnectFlag = true; },
  RESET_USER_DISCONNECT_FLAG(state) { state.isUserDisconnectFlag = false; },
  SET_IS_CONNECTED_TO_BSC_NETWORK(state) { state.isConnectedToBSCNetwork = true; },
  RESET_IS_CONNECTED_TO_BSC_NETWORK(state) { state.isConnectedToBSCNetwork = false; },
  ASSIGN_WALLET_ADDRESS(state, payload) { state.walletAddress = payload; },
  RESET_WALLET_ADDRESS(state) { state.walletAddress = ''; },
};


const actions = {
  /**
   * @sets {Boolean} state.isMetaMaskInstalled
   * @returns Boolean
   */
  setIsMetaMaskInstalled({ commit }) {
    const isMetaMaskInstalled = window.ethereum?.isMetaMask;
    if (isMetaMaskInstalled) commit('SET_IS_METAMASK_INSTALLED');
    return isMetaMaskInstalled;
  },

  /**
   * @sets {Boolean} state.isConnectedToBSCNetwork
   * @sets {string} state.walletAddress
   * @listens ethereum#connect Emitted on connection to the chain, not to the
   *   user wallet or account. This is not a user event.
   *
   *   RESPONSE: Make wallet ready.
   * @listens ethereum#disconnect Emitted only on network connectivity issues or
   *   unforeseen errors. Once emitted, MetaMask will no longer accept any new
   *   requests and the page must be reloaded. This is not a user event.
   *
   *   RESPONSE: The app should show a non-dismissible modal dialogue with a
   *   refresh page prompt over a UI disabling screen* (as if "frozen".) Log the
   *   error.
   *
   *   *While the app is not necessarily COMPLETELY borked as all non-wallet
   *   content remains perfectly functional, because the critical wallet-related
   *   functions, arguably the app's core functions, are irrecoverable without a
   *   page refresh (unlike `ethereum#chainChanged`), the only user option
   *   provided should be to refresh the page.
   * @listens ethereum#chainChanged Emitted on chain ID change, either onto the
   *   BSC Network or off it. This is a user event.
   *
   *   RESPONSE:
   *     - CHANGED TO BSC NETWORK: Do nothing. If the user simply reverts back,
   *       then everything should be unchanged. If, however, the user changes
   *       the wallet address, etc., then that will be attended to elsewhere.
   *     - CHANGED TO ELSE: Change to the Log Out page w/ a custom message, e.g.
   *       "You have disconnected. Please re-connect to the BSC Network to
   *       continue."
   * @listens ethereum#accountsChanged Emitted on either an account change or
   *   disconnect. This is a user event.
   *
   *   RESPONSE:
   *     - ACCOUNT CHANGE: Show a (dismissible) flash message to alert/affirm an
   *       account change and re-query wallet content. While an immediate re-
   *       query may be premature, it greatly simplifies the code as, otherwise,
   *       watchers will have to be included each relevant component.
   *     - ACCOUNT DISCONNECT: Change to the Log Out page w/ a custom message,
   *       e.g. "You have disconnected. Please re-connect your account to
   *       continue."
   */
  initWallet({ commit, dispatch }) {
    const BSCNetworkID = process.env.VUE_APP_CHAIN_ID;

    /**
     * @sets {Boolean} state.isConnectedToBSCNetwork
     * @sets {string} state.walletAddress
     */
    const makeWalletReady = function () {
      metaMaskHelper.getChainID().then((chainID) => {
        if (chainID === BSCNetworkID) {
          commit('SET_IS_CONNECTED_TO_BSC_NETWORK');
        } else {
          commit('RESET_IS_CONNECTED_TO_BSC_NETWORK');
        }
      });

      // While MetaMask maybe installed and connected to the chain, it may not
      // necessarily be connected to the user wallet.
      metaMaskHelper.getUserWalletAddress().then((walletAddress) => {
        commit('ASSIGN_WALLET_ADDRESS', walletAddress);
      });
    };

    /**
     * The app is subject to race conditions involving MetaMask's (asynchronous)
     * connection to the chain, where it could already be connected or not.
     *
     *   - If already connected, run `makeWalletReady()` immediately below and
     *     the on-connect event further below will not (until at least after a
     *     "disconnect" event.)
     *   - If not yet connected, then `makeWalletReady()` only run after the
     *     on-connect event.
     *
     * Regarding the meaning of `isConnected` or `connect`, see ethereum#connect
     * above.
     */
    if (window.ethereum.isConnected()) {
      makeWalletReady();
    }

    window.ethereum
      .on('connect', () => {
        makeWalletReady();
      })
      .on('disconnect', (error) => {
        commit('SET_IS_METAMASK_ERROR');
        commit('RESET_IS_CONNECTED_TO_BSC_NETWORK');
        commit('RESET_WALLET_ADDRESS');
        console.log(error);
        // TODO: Log error to Google Analytics
      })
      .on('chainChanged', (chainID) => {
        if (chainID === BSCNetworkID) {
          commit('SET_IS_CONNECTED_TO_BSC_NETWORK');
        } else {
          commit('RESET_IS_CONNECTED_TO_BSC_NETWORK');
          commit('SET_USER_DISCONNECT_FLAG');
        }
      })
      .on('accountsChanged', (accounts) => {
        if (accounts.length) {
          console.log('Account has changed');
          commit('ASSIGN_WALLET_ADDRESS', accounts[0]);
          dispatch('setWalletTokensWrapper', true);
        } else {
          console.log('Wallet disconnected');
          commit('SET_USER_DISCONNECT_FLAG');
          commit('RESET_WALLET_ADDRESS');
          // router.push('/logout');
        }
      });
  },
};


const getters = {
  // Connection related getters
  isMetaMaskInstalled: (state) => state.isMetaMaskInstalled,
  isMetaMaskError: (state) => state.isMetaMaskError,
  isUserDisconnectFlag: (state) => state.isUserDisconnectFlag,
  isConnectedToBSCNetwork: (state) => state.isConnectedToBSCNetwork,
  isConnectedToWallet: (state) => state.walletAddress !== '',
  isWalletReady: (state) => (
    state.isMetaMaskInstalled && state.isConnectedToBSCNetwork && state.walletAddress !== ''
  ),
};


export default {
  state,
  mutations,
  actions,
  getters,
};
