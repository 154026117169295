<template>
  <slot></slot>

  <div v-if="!isMetaMaskInstalled" class="wallet-portal">
    Please install <a href="https://metamask.io/download.html" target="_blank">MetaMask</a>
  </div>

  <div v-else class="wallet-portal">
    Please connect your wallet to the BSC Network with MetaMask.

    <div class="button-container">
      <button @click="connectMetaMask" :disabled="isConnecting" class="black">
        {{ isConnecting ? 'Connecting...' : 'Connect to MetaMask' }}
      </button>

      <button @click="toggleHowToConnect" class="secondary-text-button">
        How to Connect
      </button>
    </div>

    <div v-show="showHowToConnect">
      How to connect The Forge
    </div>
  </div>
</template>


<script>
  import { mapGetters } from 'vuex';
  import { ethers } from 'ethers';

  export default {
    name: 'WalletPortal',
    data() {
      return {
        isConnecting: false,
        showHowToConnect: false,
      };
    },
    computed: {
      ...mapGetters([
        'isMetaMaskInstalled',
      ]),
    },
    methods: {
      async connectMetaMask() {
        this.isConnecting = true;
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        try {
          // Pop-up MetaMask window
          await provider.send('eth_requestAccounts', []);
        } catch (err) {
          console.error(err.code === 4001 ? 'User rejected request' : err);
        } finally {
          this.isConnecting = false;
        }
      },

      toggleHowToConnect() {
        this.showHowToConnect = !this.showHowToConnect;
      },
    },
  };
</script>


<style lang="scss" scoped>
  .wallet-portal {
    margin-bottom: $base-line-height * 2;
    border: $border-width--thick solid white;
    padding: 24px $base-line-height 30px;
    text-align: center;

    @include for-tablet-up {
      padding-top: 35px;
      padding-bottom: 35px;
    }
  }

  .button-container {
    margin-top: $base-line-height;
  }
</style>
