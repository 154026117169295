<template>
  <header class="sectionHeader">
    <h1>Shop</h1>
  </header>
</template>


<script>
  export default {
    name: 'ShopTokens',
  };
</script>
