/**
 * Generate wallet tokens with metadata.
 *
 * @param {string} walletAddress
 * @param {Array} walletTokenIDs
 * @param {Object} allTokens
 * @returns {Object}
 */
const makeWalletTokensWrapper = function (walletAddress, walletTokenIDs, allTokens) {
  const walletTokens =
    walletTokenIDs
      .map((token) => {
        const [tokenID, tokenQuantity] = token;
        return (tokenID in allTokens)
          ? { [tokenID]: { ...allTokens[tokenID], quantity: tokenQuantity } }
          : {};
      })
      .reduce((result, o) => Object.assign(result, o), {});
  return {
    walletAddress,
    content: walletTokens,
  };
};


/**
 * Return wallet merge formulas.
 *
 * @param {Array} walletTokenIDs
 * @param {Array} allMergeFormulas
 * @returns {Array}
 */
const makeWalletMergeFormulas = function (walletTokenIDs, allMergeFormulas) {
  return allMergeFormulas.filter((formula) => {
    const input = formula[0];
    return input.every((tokenID) => walletTokenIDs.includes(tokenID));
  });
};


export default {
  makeWalletTokensWrapper,
  makeWalletMergeFormulas,
};
