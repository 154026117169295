import axios from 'axios';


const state = {
  allTokens: {},
  allMergeFormulas: [],
};


const mutations = {
  ASSIGN_ALL_TOKENS(state, payload) { state.allTokens = payload; },
  ASSIGN_ALL_MERGE_FORMULAS(state, payload) { state.allMergeFormulas = payload; },
};


const actions = {
  /**
   * Set all token metadata.
   *
   * @param {Boolean} [isForceDownload=false]
   */
  async setAllTokens({ commit }, isForceDownload = false) {
    const isAllTokensSet = Object.keys(state.allTokens).length > 0;
    if (isAllTokensSet && !isForceDownload) return;

    try {
      const res = await axios.get('/api/token-metadata');
      commit('ASSIGN_ALL_TOKENS', res.data);
    } catch (err) {
      throw new Error(err);
    }
  },

  async setAllMergeFormulas({ commit }, isForceDownload = false) {
    const isAllMergeFormulasSet = state.allMergeFormulas.length > 0;
    if (isAllMergeFormulasSet && !isForceDownload) return;

    try {
      const res = await axios.get('/api/merge-formulas');
      commit('ASSIGN_ALL_MERGE_FORMULAS', res.data);
    } catch (err) {
      throw new Error(err);
    }
  },
};


const getters = {
  allTokens: (state) => state.allTokens,
  allMergeFormulas: (state) => state.allMergeFormulas,
};


export default {
  state,
  mutations,
  actions,
  getters,
};
