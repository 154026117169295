import axios from 'axios';
import walletHelper from '@/common/walletHelper';


const state = {
  walletTokensWrapper: { walletAddress: '', content: {} },
  walletMergeFormulas: [],
};


const mutations = {
  ASSIGN_WALLET_TOKENS_WRAPPER(state, payload) { state.walletTokensWrapper = payload; },
  ASSIGN_WALLET_MERGE_FORMULAS(state, payload) { state.walletMergeFormulas = payload; },
};


const actions = {
  /**
   * @param {Boolean} [isForceDownload=false]
   * @sets {Object} state.walletTokensWrapper
   *   Ex. {
   *         "walletAddress": "123456789",
   *         "content": {
   *           "100": { id: "100", title: "One", quantity: 1 },
   *           "200": { id: '200", title: "Two", quantity: 2 }
   *         }
   *       }
   */
  async setWalletTokensWrapper({ commit, dispatch, getters }, isForceDownload = false) {
    if (getters.isWalletTokensWrapperSet && !isForceDownload) return;

    try {
      const responses = await Promise.all([
        axios.get('/api/wallet'),
        dispatch('setAllTokens'),
      ]);

      const walletTokenIDs = responses[0].data;
      const walletTokensWrapper = walletHelper.makeWalletTokensWrapper(
        state.walletAddress,
        walletTokenIDs,
        getters.allTokens,
      );
      commit('ASSIGN_WALLET_TOKENS_WRAPPER', walletTokensWrapper);
    } catch (err) {
      // TODO: Show error.
      console.error('setWalletTokensWrapper', err);
    }
  },

  /**
   * @sets {Array} state.walletMergeFormulas
   *   Ex. [
   *         [["100", "101"], ["201"]],
   *         [["101", "101"], ["202"]]
   *       ]
   */
  async setWalletMergeFormulas({ commit, dispatch, getters }) {
    try {
      await dispatch('setAllMergeFormulas');

      const walletTokenIDs = Object.keys(state.walletTokensWrapper.content);
      const walletMergeFormulas = walletHelper.makeWalletMergeFormulas(
        walletTokenIDs,
        getters.allMergeFormulas,
      );
      commit('ASSIGN_WALLET_MERGE_FORMULAS', walletMergeFormulas);
    } catch (err) {
      // TODO: Show error.
      console.error('setWalletMergeFormulas', err);
    }
  },
};


const getters = {
  isWalletTokensWrapperSet: (state) => {
    const isAddressSet = state.walletTokensWrapper.walletAddress !== '';
    const isAddressCurrent = state.walletTokensWrapper.walletAddress === state.walletAddress;
    return isAddressSet && isAddressCurrent;
  },
  walletTokens: (state) => state.walletTokensWrapper.content,
  walletTokenCount: (state) => (
    Object.values(state.walletTokensWrapper.content).reduce((sum, o) => sum + o.quantity, 0)
  ),
  walletTokenClassCount: (state) => Object.keys(state.walletTokensWrapper.content).length,
  walletTokenByID: (state) => (tokenID) => state.walletTokensWrapper.content[tokenID],
  mergeFormulasByID: (state) => (tokenID) => {
    // TODO: Consider quantity!
    // TODO: Cache this
    return state.walletMergeFormulas.filter((f) => f[0].includes(String(tokenID)));
  },
};


export default {
  state,
  mutations,
  actions,
  getters,
};
