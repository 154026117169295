const getChainID = async function () {
  try {
    return await window.ethereum.request({ method: 'eth_chainId' });
  } catch (error) {
    return '';
  }
};

const getUserWalletAddress = async function () {
  try {
    const addresses = await window.ethereum.request({ method: 'eth_accounts' });
    return (addresses.length > 0) ? addresses[0] : '';
  } catch (error) {
    return '';
  }
};


export default {
  getChainID,
  getUserWalletAddress,
};
