<template>
  <WalletPortal v-if="!isWalletReady" />
  <TokenDetail v-else
    v-bind="token"
    :tokens="walletTokens"
    :tokenCount="walletTokenCount"
    :tokenClassCount="walletTokenClassCount"
  />
</template>


<script>
  import { mapGetters } from 'vuex';
  import WalletPortal from '@/components/WalletPortal.vue';
  import TokenDetail from '@/components/TokenDetail.vue';

  export default {
    name: 'WalletToken',
    props: [
      'tokenID',
    ],
    components: {
      WalletPortal,
      TokenDetail,
    },
    created() {
      if (this.isWalletReady) {
        this.$store.dispatch('setWalletTokensWrapper');
      }
    },
    computed: {
      ...mapGetters([
        'isWalletReady',
        'walletTokens',
        'walletTokenCount',
        'walletTokenClassCount',
      ]),
      token() {
        return (this.tokenID)
          ? this.$store.getters.walletTokenByID(Number(this.tokenID))
          : null;
      },
    },
    watch: {
      isWalletReady(newVal) {
        if (newVal) this.$store.dispatch('setWalletTokensWrapper');
      },
    },
  };
</script>
