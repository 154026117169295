<template>
  <section>
    <header class="sectionHeader">
      <h2 class="h3">Merge</h2>
    </header>

    <form v-if="tokenMergeFormulas.length">
      <div v-for="(formula, index) in tokenMergeFormulas" :key="index">
        {{ formula[0] }} → {{ formula[1] }}
      </div>
    </form>

    <div v-else>
      No tokens to merge
    </div>
  </section>
</template>


<script>
  export default {
    name: 'MergeFormulas',
    props: {
      tokenID: { type: String, required: true },
    },
    created() {
      this.$store.dispatch('setWalletMergeFormulas');
    },
    computed: {
      tokenMergeFormulas() {
        return this.$store.getters.mergeFormulasByID(Number(this.tokenID));
      },
    },
  };
</script>
