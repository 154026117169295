<template>
  <article v-if="id">
    <section class="token-detail">
      <div>
        <video autoplay controls loop playsinline controlslist="nodownload"
          :poster="video_poster"
          :src="video_src"
        ></video>
      </div>
      <div>
        <header class="sectionHeader --noMarginTop">
          <h1 class="h2 no-underline u-normalcase">{{ title }}</h1>
          <p>Qty: {{ quantity }}</p>
        </header>
        <div class="rte" v-html="description_html"></div>
        <ul>
          <li>10 owners</li>
        </ul>
        <dl>
          <dt>Contract address</dt>
          <dd>0x0000000000000000000000000000000000000000</dd>
          <dt>Token ID</dt>
          <dd>00000000000000000000000000000000000000000000000000000000000000000000000000000</dd>
          <dt>Token Standard</dt>
          <dd>ERC-1155</dd>
          <dt>Block Chain</dt>
          <dd>Polygon</dd>
        </dl>
      </div>
    </section>

    <section>
      <MergeFormulas :tokenID="id" />
    </section>
  </article>
  <article v-else>
    Sorry, not found
  </article>

  <aside>
    <TokenList
      :tokens="otherTokens"
      :tokenCount="tokenCount"
      :tokenClassCount="tokenClassCount"
      density="H"
    />
  </aside>

  <h2 class="h3">Buy Mergable Tokens</h2>
</template>


<script>
  import MergeFormulas from '@/components/MergeFormulas.vue';
  import TokenList from '@/components/TokenList.vue';

  export default {
    name: 'TokenDetail',
    props: {
      quantity: { type: Number, required: true },
      id: { type: String, required: true },
      title: { type: String, required: true },
      description_html: { type: String, required: true },
      video_poster: { type: String, required: true },
      video_src: { type: String, required: true },
      tokens: { type: Object, required: true },
      tokenCount: { type: Number, required: true },
      tokenClassCount: { type: Number, required: true },
    },
    components: {
      MergeFormulas,
      TokenList,
    },
    computed: {
      otherTokens() {
        const { [this.id]: _, ...otherTokens } = this.tokens;
        return otherTokens;
      },
    },
  };
</script>


<style lang="scss" scoped>
  @include for-tablet-up {
    .token-detail {
      display: grid;

      // Same proportion as on P4 product page.
      grid-template-columns: 3fr 2fr;
      gap: $section-gap;
    }
  }

  dt {
    font-weight: bold;
  }

  dd {
    line-break: anywhere;
  }
</style>
