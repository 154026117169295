<template>
  <header class="sectionHeader">
    <h1>Informa<wbr>tion</h1>
  </header>

  <InfoContent />
</template>


<script>
  import InfoContent from '@/components/InfoContent.vue';

  export default {
    name: 'Info',
    components: {
      InfoContent,
    },
  };
</script>
