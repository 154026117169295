<template>
  <article>
    <router-link :to="`/wallet/token/${id}`" >
      <div class="poster-wrapper">
        <img :src="video_poster" alt="">
        <span class="qty" title="Token quantity">
          <span class="u-screen-reader-only">Qty: </span>{{ quantity }}
        </span>
      </div>
      <p>{{ title }}</p>
    </router-link>
  </article>
</template>


<script>
  export default {
    name: 'TokenListItem',
    props: {
      quantity: { type: Number, required: true },
      id: { type: String, required: true },
      title: { type: String, required: true },
      description_html: { type: String, required: true },
      video_poster: { type: String, required: true },
      video_src: { type: String, required: true },
    },
  };
</script>


<style lang="scss" scoped>
  article {
    position: relative;
    font-size: $small-font-size;
  }

  a {
    display: block;
    text-decoration: none;

    p {
      margin-top: 7px;
      padding: 0 $base-font-size;
    }

    &:hover,
    &:hover:visited {
      img {
        opacity: .75;
      }

      p {
        color: white;
        text-decoration: underline;
      }
    }

    &:visited {
      color: $white--dim;
    }
  }

  .poster-wrapper {
    position: relative;
  }

  .qty {
    position: absolute;
    right: 0;
    bottom: 0;
    display: grid;
    align-content: center;
    width: 3.66ch;
    aspect-ratio: 1;
    background-color: white;
    color: $black;
  }
</style>
