import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/HomeView.vue';
import WalletTokens from '@/views/WalletTokensView.vue';
import WalletToken from '@/views/WalletTokenView.vue';
import ShopTokens from '@/views/ShopTokensView.vue';
// import LogOut from '@/views/LogOut.vue';
import Info from '@/views/InfoView.vue';
import NotFound from '@/views/NotFoundView.vue';


/**
 * The router does not have access to the Vuex store nor the `window.ethereum`
 * object at initial page landings, hence the wallet connection status and such
 * should be resolved at the component level.
 */
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/wallet',
    redirect: '/wallet/tokens',
  },
  {
    path: '/wallet/tokens',
    name: 'Wallet Tokens',
    component: WalletTokens,
  },
  {
    path: '/wallet/token/:tokenID',
    name: 'Wallet Token',
    component: WalletToken,
    props: true,
    beforeEnter: (to, from, next) => {
      if (!to.params.tokenID) next('/wallet/tokens');
      else next();
    },
  },
  {
    path: '/shop',
    redirect: '/shop/tokens',
  },
  {
    path: '/shop/tokens',
    name: 'Shop Tokens',
    component: ShopTokens,
  },
  // {
  //   path: '/logout',
  //   name: 'logOut',
  //   component: LogOut,
  //   beforeEnter: (to, from, next) => {
  //     const isWalletReady = localStorage.getItem('isWalletReady');
  //     if (isWalletReady) next('/wallet/tokens');
  //     else next();
  //   },
  // },
  {
    path: '/information',
    name: 'Info',
    component: Info,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: NotFound,
  },
];


export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
