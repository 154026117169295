<template>
  <AlertFlash v-show="isUserDisconnectFlag && !isConnectedToBSCNetwork"
    msg="Your wallet has been disconnected form the <b>BSC Network</b>. Please
    reconnect to continue."
    @clicked="dismissAlert"
  />
  <AlertFlash v-show="isUserDisconnectFlag && !isConnectedToWallet"
    msg="Your wallet has been disconnected. Please reconnect to continue."
    @clicked="dismissAlert"
  />
  <AlertModal v-show="isMetaMaskError"
    msg="Sorry, there was a problem. Please refresh this page to continue."
    buttonText="Refresh Page"
    @clicked="refreshPage"
  />

  <TheNav />
  <main class="page-width">
    <router-view/>
  </main>
  <TheFooter />
</template>


<script>
  import { mapGetters, mapMutations } from 'vuex';
  import AlertFlash from '@/components/AlertFlash.vue';
  import AlertModal from '@/components/AlertModal.vue';
  import TheNav from '@/components/TheNav.vue';
  import TheFooter from '@/components/TheFooter.vue';

  export default {
    name: 'App',
    components: {
      AlertFlash,
      AlertModal,
      TheNav,
      TheFooter,
    },
    async created() {
      const isMetaMaskInstalled = await this.$store.dispatch('setIsMetaMaskInstalled');
      if (isMetaMaskInstalled) {
        this.$store.dispatch('initWallet');
      }
    },
    computed: {
      ...mapGetters([
        'isMetaMaskError',
        'isUserDisconnectFlag',
        'isConnectedToBSCNetwork',
        'isConnectedToWallet',
      ]),
    },
    methods: {
      ...mapMutations({
        dismissAlert: 'RESET_USER_DISCONNECT_FLAG',
      }),
      refreshPage() {
        this.$router.go();
      },
    },
  };
</script>
