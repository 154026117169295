import { createStore } from 'vuex';
import tokens from './_tokensStore';
import walletConnection from './_walletConnectionStore';
import walletContent from './_walletContentStore';


export default createStore({
  modules: {
    tokens,
    walletConnection,
    walletContent,
  },
});
