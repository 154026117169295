<template>
  <footer class="page-width">
    <ul class="u-inline-list">
      <li><a class="button-ish-link" href="https://partsof4.com/pages/nft">NFT</a></li>
      <li><a class="button-ish-link" href="https://partsof4.com/pages/p4c">P4C</a></li>
      <li><a class="button-ish-link" href="https://partsof4.com/pages/1inch-x-parts-of-four">1inch × P4</a></li>
      <li><a class="button-ish-link" href="https://opensea.io/partsof4">OpenSea</a></li>
      <li><a class="button-ish-link" href="https://etherscan.io/token/0x8037b1b69d6fa63a9cc053c25f7e168e6e6d857a">Etherscan</a></li>
      <li><a class="button-ish-link" href="https://app.unicrypt.network/amm/uni-v2/pair/0x990ffA37B4bd515F14831d9ec52AF9503E5e6790">Unicrypt Liquidity Lock</a></li>
      <li><a class="button-ish-link" href="https://cdn.shopify.com/s/files/1/0947/8200/files/Parts-of-Four-Token_final-audit-report_1636641572730.pdf?v=1638899688">Audit</a></li>
      <li><a class="button-ish-link" href="https://t.me/partsof4">Telegram</a></li>
      <li><a class="button-ish-link" href="https://discord.gg/X9DvnFqfG9">Discord</a></li>
      <li><a class="button-ish-link" href="https://partsof4.medium.com">Medium</a></li>
      <li><a class="button-ish-link" href="https://twitter.com/partsof4">Twitter</a></li>
      <li><a class="button-ish-link" href="//www.instagram.com/partsof4/">Instagram</a></li>
      <li><a class="button-ish-link" href="https://giphy.com/partsof4">Giphy</a></li>
      <li><a class="button-ish-link" href="https://linktr.ee/partsof4">Linktree</a></li>
    </ul>
    <p class="fine-print text-small">The Forge by <a href="https://partsof4.com">Parts of Four</a></p>
  </footer>
</template>


<script>
  export default {
    name: 'TheFooter',
  };
</script>


<style lang="scss" scoped>
  footer {
    margin-top: 120px
  }

  ul {
    gap: 1rem 0.5rem;
  }

  .fine-print {
    margin-top: calc(1em * 1.4);
  }
</style>
