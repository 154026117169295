<template>
  <header class="sectionHeader --noMarginTop">
    <video autoplay loop playsinline>
      <source src="@/assets/media/amulet--1366x888_30f_0ch_28q.mp4" type="video/mp4">
    </video>
    <h1 class="no-underline">The Forge</h1>
  </header>

  <WalletPortal v-if="!isWalletReady" />
  <TokenList v-else
    :tokens="walletTokens"
    :tokenCount="walletTokenCount"
    :tokenClassCount="walletTokenClassCount"
  />

  <InfoContent />
</template>


<script>
  import { mapGetters } from 'vuex';
  import WalletPortal from '@/components/WalletPortal.vue';
  import TokenList from '@/components/TokenList.vue';
  import InfoContent from '@/components/InfoContent.vue';

  export default {
    name: 'Home',
    components: {
      WalletPortal,
      TokenList,
      InfoContent,
    },
    created() {
      if (this.isWalletReady) {
        this.$store.dispatch('setWalletTokensWrapper');
      }
    },
    computed: {
      ...mapGetters([
        'isWalletReady',
        'walletTokens',
        'walletTokenCount',
        'walletTokenClassCount',
      ]),
    },
    watch: {
      isWalletReady(newVal) {
        if (newVal) this.$store.dispatch('setWalletTokensWrapper');
      },
    },
  };
</script>


<style lang="scss" scoped>
  header {
    display: grid;

    // Modern CSS centering convention calls for using the `place-content`
    // property but it doesn't work for absolutely positioned child elements.
    place-items: center;
  }

  h1 {
    position: absolute;
    display: inline-block;
    padding: 5px 17px;

    // Magic number: visually match border-width to font weight.
    border: 5px solid white;
    text-align: center;
  }
</style>
