<template>
  <WalletPortal v-if="!isWalletReady">
    <h1>Wallet</h1>
  </WalletPortal>
  <TokenList v-else
    :tokens="walletTokens"
    :tokenCount="walletTokenCount"
    :tokenClassCount="walletTokenClassCount"
  >
    <h1>Wallet</h1>
  </TokenList>
</template>


<script>
  import { mapGetters } from 'vuex';
  import WalletPortal from '@/components/WalletPortal.vue';
  import TokenList from '@/components/TokenList.vue';

  export default {
    name: 'WalletTokens',
    components: {
      WalletPortal,
      TokenList,
    },
    created() {
      if (this.isWalletReady) {
        this.$store.dispatch('setWalletTokensWrapper');
      }
    },
    computed: {
      ...mapGetters([
        'isWalletReady',
        'walletTokens',
        'walletTokenCount',
        'walletTokenClassCount',
      ]),
    },
    watch: {
      isWalletReady(newVal) {
        if (newVal) this.$store.dispatch('setWalletTokensWrapper');
      },
    },
  };
</script>
