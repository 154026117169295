<script setup>
  import { ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { onClickOutside } from '@vueuse/core';

  const menuWrapper = ref();
  const isMenuActive = ref(false);

  function toggleMenu() {
    isMenuActive.value = !isMenuActive.value;
  }

  function hideMenu() {
    isMenuActive.value = false;
  }

  onClickOutside(menuWrapper, hideMenu);
  watch(useRoute(), hideMenu);
</script>


<template>
  <nav class="page-width">
    <router-link to="/" class="site-name">The Forge</router-link>

    <div ref="menuWrapper">
      <button @click="toggleMenu" :class="{ isMenuActive }">
        <svg width="36" height="16" xmlns="http://www.w3.org/2000/svg">
          <path stroke="#fff" stroke-width="2" d="M0,1h36 M0,8h36 M0,15h36" />
        </svg>
      </button>

      <ul :class="{ isMenuActive }" class="u-inline-list">
        <li><router-link @click.prevent="hideMenu" to="/wallet/tokens">Wallet</router-link></li>
        <li><router-link @click.prevent="hideMenu" to="/re-mint/">Re-Mint</router-link></li>
        <li><router-link @click.prevent="hideMenu" to="/shop/tokens">Shop</router-link></li>
        <li><router-link @click.prevent="hideMenu" to="/information">Information</router-link></li>
      </ul>
    </div>
  </nav>
</template>


<style lang="scss" scoped>
  nav {
    --height: 5rem;
    --desktop-nav-gap: 1rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    // Visually align nav items to page margin
    padding: 0 calc($section-gap - var(--desktop-nav-gap));

    @include for-phone-only {
      // For positioning <ul>
      position: relative;

      // Align <button> flush-right (but maintain visual alignment of menu icon
      // to page margin)
      padding-right: 0;
    }
  }

  .site-name {
    font-weight: $bold-font-weight;
  }

  a {
    display: grid;
    place-content: center;
    height: var(--height);
    padding: 0 var(--desktop-nav-gap);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      background-color: $pink--highlight;
    }

    @include for-phone-only {
      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }

  button {
    @include for-tablet-up {
      display: none;
    }

    height: var(--height);
    border: none;
    border-radius: 0;
    padding: 0 $page-gutter;
    background-color: transparent;

    &.isMenuActive {
      background-color: black;
    }
  }

  ul {
    @include for-phone-only {
      display: none !important; // Override `.u-inline-list`
      position: absolute;
      right: 0;
      top: 100%; // Position immediately below <nav>
      z-index: $z-index--1;
      padding: 1em 0 $relative-line-height;
      flex-direction: column;
      background-color: $black-transparent--light;
      box-shadow: $box-shadow--menu;
      backdrop-filter: $blur--medium;

      &.isMenuActive {
        display: flex !important; // Override `.u-inline-list`
      }

      a {
        display: block;
        height: auto;
        width: 55vw;
        padding: 5px 1em;
        text-align: center;

        &:hover,
        &:active {
          background-color: $black--highlight;
        }
      }
    }

    .router-link-active {
      text-decoration: underline;
      text-decoration-thickness: 4px;
      text-decoration-skip-ink: none;

      @include for-tablet-up {
        // Visually match hover underline.
        //
        // On iOS Safari, negative values seems to break styling, which may be
        // related to a broken support for the text-decoration-skip-ink property.
        text-underline-offset: -.5px;
      }
    }
  }
</style>
