<template>
  <div class="content">
    <nav class="content__toc">
      <ul class="u-stripped-list">
        <li><a href="#what-is-the-forge">What is the Forge?</a></li>
        <li><a href="#how-to-use-the-forge">How to Use the Forge</a></li>
        <li><a href="#how-to-buy-tokens">How to Buy Tokens</a></li>
        <li><a href="#how-to-re-mint-tokens">How to Re-mint Tokens</a></li>
      </ul>
    </nav>

    <!-- eslint-disable max-len -->
    <div class="content__main">
      <section>
        <h2 class="h3" id="what-is-the-forge">What is the Forge?</h2>
        <p>In mollis purus facilisis, porta ligula at, dictum urna. Quisque et lacinia turpis, vel tristique nisi. Morbi luctus iaculis dolor, ac facilisis libero ornare sed. Mauris blandit ante vitae rhoncus mollis.</p>
        <p>Aliquam massa est, efficitur nec diam ut, laoreet finibus turpis. Nullam vehicula a eros laoreet faucibus. Aenean vel lorem ac neque sollicitudin rhoncus in ac lacus. Curabitur ante sapien, venenatis nec ante nec, dignissim efficitur ex. Etiam quis interdum tortor, in pharetra nunc. Quisque eget nibh luctus, feugiat dolor nec, dictum ante.</p>
      </section>

      <section>
        <h2 class="h3" id="how-to-use-the-forge">How to Use the Forge</h2>
        <p>Suspendisse non ante tincidunt, iaculis nisl eget, porta leo. In at bibendum ante, sollicitudin molestie augue. Vivamus justo augue, porttitor non justo sed, consequat aliquam enim. Sed vel eleifend justo. Maecenas metus justo, ultricies id sodales eu, fringilla vel urna..</p>
        <p>Quisque non tellus non metus convallis porttitor. Nulla tempor tellus vel congue eleifend. Fusce nec urna quis risus lacinia lobortis ac eu quam. Suspendisse vulputate ut orci in euismod. In semper odio metus, id consectetur mi aliquam id. Integer risus dolor, venenatis id dolor eget, fringilla rutrum arcu. Nullam mattis ante id leo lobortis, sit amet egestas sem accumsan.</p>
      </section>

      <section>
        <h2 class="h3" id="how-to-buy-tokens">How to Buy Tokens</h2>
        <p>Aliquam fringilla lacinia elementum. Pellentesque hendrerit finibus lectus et pretium. Aenean porttitor mi in ante aliquet, id vestibulum nunc cursus. Quisque finibus tempor ligula, id euismod orci tempor at. Suspendisse gravida, risus sit amet malesuada gravida, odio diam maximus mi, quis lacinia velit felis sed enim. Maecenas consectetur sapien a semper facilisis. Vestibulum egestas sem nec nisl rutrum molestie.</p>
      </section>

      <section>
        <h2 class="h3" id="how-to-re-mint-tokens">How to Re-mint Tokens</h2>
        <p>Cras tortor magna, aliquet sit amet sodales id, imperdiet id urna. Etiam egestas odio sapien, id ultrices lacus maximus quis. Donec faucibus nisi tortor, sed gravida ligula accumsan non. Integer scelerisque lobortis urna non hendrerit.</p>
        <p>Pellentesque a congue eros, quis ultricies lectus. Vivamus et eros velit. Ut ut sollicitudin leo. Ut ut blandit est. Nam molestie porttitor tincidunt. Proin condimentum accumsan est. Duis dignissim aliquam tempus. Cras aliquam porttitor mollis. Nam eu imperdiet felis, sit amet pulvinar eros. Duis id ipsum quis orci porttitor bibendum. In gravida elit quis erat laoreet condimentum.</p>
        <p>Nunc et quam non ligula ullamcorper laoreet et in sapien. Vestibulum in ex vitae metus viverra maximus id nec purus. Morbi nisl urna, pulvinar ullamcorper blandit at, efficitur a odio. Pellentesque mauris neque, vulputate et convallis at, varius lacinia erat.</p>
      </section>
    </div>
  </div>
</template>


<script>
  export default {
    name: 'InfoContent',
  };
</script>


<style lang="scss" scoped>
  section {
    margin-bottom: calc($base-line-height * 2);
  }
</style>
