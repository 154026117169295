<template>
  <dialog open>
    <p v-html="msg" class="u-stripped-text"></p>
    <button @click="this.$emit('clicked')" class="close" title="Dismiss">
      <span class="u-screen-reader-only">Dismiss</span>
    </button>
  </dialog>
</template>


<script>
  export default {
    name: 'AlertFlash',
    props: {
      msg: { type: String, required: true },
    },
  };
</script>


<style lang="scss" scoped>
  dialog {
    width: 100%;
    padding: ($section-gap * 2) $section-gap;
    background-color: $red;
    color: $red__white-text;
  }

  p {
    text-align: center;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
